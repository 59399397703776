<template>
  <div class="renew" tabindex="0" @click="isModalOpen = true" @keypress.space="isModalOpen = true">
    <AppIcon class="refresh-icon" name="refresh-orange" size="16px" />

    <AppText ml="10px">
      {{ t('merchantBilling.balance.label.renewNow') }}
    </AppText>

    <CurrencyFormatter
      class="amount"
      size="13px"
      opacity="0.4"
      :value="amount"
    />

    <AppIcon class="arrow-icon rotate-90" name="arrow-bracket" size="18px" />
  </div>

  <ModalToForceRenew
    v-model="isModalOpen"
    :amount="amount"
    :subscription="subscription"
    :left-payments="leftPayments"
  />
</template>

<script>
import { ref, computed } from 'vue';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';

import ModalToForceRenew from './ModalToForceRenew.vue';

export default {
  name: 'RenewButton',
  components: {
    CurrencyFormatter,
    ModalToForceRenew,
  },
  props: {
    subscription: {
      type: Object,
      default: () => ({}),
    },
    leftPayments: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const isModalOpen = ref(false);

    const amount = computed(() => {
      if (props.subscription.cycle === 'MONTHLY') {
        return props.subscription.plan?.perMonth;
      }

      return props.subscription.plan?.perYear;
    });

    return { isModalOpen, amount };
  },
};
</script>

<style lang="scss" scoped>
.renew {
  cursor: pointer;
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--color-black-01);
  border-radius: 4px;
  padding: 0 10px;
  @include transition-base('color, border');
  @include focus-visible;

  .arrow-icon {
    margin-left: 4px;
  }

  :deep(.icon.arrow-icon path) {
    fill: var(--color-black-04);
    @include transition-base(stroke);
  }

  .amount {
    flex-grow: 1;
    justify-content: right;
    @include transition-base(opacify);
  }

  &:hover {
    color: var(--color-primary);
    border-color: var(--color-primary);
    @include transition-base('color, border');

    :deep(.icon.arrow-icon path) {
      fill: var(--color-primary);
      @include transition-base('fill');
    }

    .amount {
      :deep(.text) {
        opacity: 1;
        @include transition-base(opacify);
      }
    }
  }
}
</style>
