<template>
  <SmoothModal
    :model-value="isOpenProp"
    width="480px"
    :has-header="false"
    @update:model-value="isOpenProp = false"
  >
    <div class="renew">
      <AppText
        variant="div"
        size="18px"
        mt="35px"
        class="font-medium"
      >
        {{ t('merchantBilling.modalToRenew.title') }}
      </AppText>

      <AppText
        size="14px"
        :line-height="1.5"
        opacity="0.5"
        mt="20px"
      >
        {{ t('merchantBilling.modalToRenew.description') }}
      </AppText>

      <AppText
        variant="div"
        size="12px"
        :line-height="1.3"
        opacity="0.4"
        mt="28px"
        class="font-medium"
        type="uppercase"
      >
        {{ t('merchantBilling.modalToRenew.yourPlan') }}
      </AppText>

      <div class="plan-info">
        <AppIcon
          v-if="subscription.plan?.name !== 'PRO'"
          class="plan-icon"
          :name="planIcon"
          size="20px"
        />
        <AppText size="15px" class="font-medium is-first-letter-capitalized">
          {{ subscription.plan?.name }}
        </AppText>
        <AppText
          size="10px"
          :line-height="1"
          opacity="0.3"
          pl="10px"
          pr="10px"
        >
          |
        </AppText>
        <AppText class="font-medium">
          <CurrencyFormatter :value="amount" />
        </AppText>
        <AppText class="text-lowercase">
          &nbsp;/&nbsp;{{ cycle }}
        </AppText>
      </div>

      <InfoBlock
        class="payments-info"
        :title-left="t('merchantBilling.modalToRenew.paymentsLeftNow')"
        :description-left="paymentsLeft"
        :title-right="t('merchantBilling.modalToRenew.paymentsLeftAfter')"
        :description-right="paymentsWillLeft"
      />

      <InfoBlock
        class="dates-info"
        :title-left="t('merchantBilling.modalToRenew.currentExpiryDate')"
        :description-left="endDate"
        :title-right="t('merchantBilling.modalToRenew.newExpiryDate')"
        :description-right="endDateNew"
      />

      <AppTooltip width="252px" :is-enabled="!isLimitReached">
        <FButton
          no-margin
          :disabled="!isLimitReached"
          :class="{ 'no-events': !isLimitReached }"
          is-full-width
          @click="onForceRenew"
        >
          {{ t('merchantBilling.modalToRenew.pay') }}&nbsp;<CurrencyFormatter :value="amount" />
        </FButton>

        <template #text>
          {{ t('merchantBilling.modalToRenew.footer') }}
        </template>
      </AppTooltip>
    </div>
  </SmoothModal>
</template>

<script>
import { computed } from 'vue';
import dayjs from 'dayjs';

import CurrencyFormatter from '@/components/Helpers/CurrencyFormatter.vue';
import SmoothModal from '@/components/SmoothModal';
import AppTooltip from '@/components/AppTooltip.vue';

import { emitter } from '@/composables/useBus';
import { renewPlan } from '@/api/billing';
import { useModel } from '@/composables/useModel';
import { useSettings } from '@/composables/useSettings';
import { makeFirstLetterUppercased } from '@/utils/functions';
import { accountTypes } from '@/common/data';
import { i18n } from '@/plugins/localization';

import InfoBlock from './InfoBlock.vue';

export default {
  name: 'ModalToForceRenew',
  components: {
    SmoothModal,
    CurrencyFormatter,
    InfoBlock,
    AppTooltip,
  },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    leftPayments: {
      type: Number,
      default: 0,
    },
    subscription: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { dateFormat } = useSettings();
    const { value: isOpenProp } = useModel(props, emit, { prop: 'modelValue' });
    const { t } = i18n.global;

    const cycle = computed(() => {
      console.log(props.subscription?.cycle);
      if (props.subscription?.cycle === 'YEARLY') {
        return t('cycle.YEAR');
      }
      if (props.subscription?.cycle === 'MONTHLY') {
        return t('cycle.MONTH');
      }
      return props.subscription?.cycle?.toLowerCase();
    });
    const planIcon = computed(() => accountTypes[props.subscription.plan?.name?.toLowerCase()].icon);

    const isLimitReached = computed(() => (paymentsLeft.value <= ((props.subscription?.paymentsLimit || 0) * 0.1)));

    const paymentsLeft = computed(() => {
      const { isBlack, paymentsLimit } = checkBlackPlan();

      if (isBlack) {
        return paymentsLimit;
      }

      return props.leftPayments || 0;
    });

    const paymentsWillLeft = computed(() => {
      const { isBlack, paymentsLimit } = checkBlackPlan();

      if (isBlack) {
        return paymentsLimit;
      }

      return paymentsLeft.value + (props.subscription?.paymentsLimit || 0);
    });

    const endDate = computed(() => (dayjs(props.subscription.endDate).format(dateFormat.value)));

    const endDatePeriod = computed(() => (props.subscription.cycle === 'MONTHLY' ? 'month' : 'year'));
    const endDateNew = computed(() => (dayjs(props.subscription.endDate).add(1, endDatePeriod.value).format(dateFormat.value)));

    const onForceRenew = async () => {
      const { isSuccess } = await renewPlan();

      if (isSuccess) {
        emitter.emit('update-billing-page');
        emit('update:modelValue', false);
      }
    };

    const checkBlackPlan = () => {
      if (props.subscription?.plan?.name.toLowerCase() === 'black') {
        return { isBlack: true, paymentsLimit: props.subscription?.paymentsLimit };
      }

      return { isBlack: false };
    };

    return {
      isOpenProp,
      onForceRenew,
      cycle,
      paymentsLeft,
      paymentsWillLeft,
      endDate,
      endDateNew,
      isLimitReached,
      makeFirstLetterUppercased,
      planIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.renew {
  display: flex;
  flex-direction: column;

  .plan-info {
    height: 20px;
    display: flex;
    align-items: center;
    margin-top: 15px;

    .plan-icon {
      margin-right: 8px;
    }
  }

  .payments-info {
    margin-top: 28px;
  }

  .dates-info {
    margin-top: 18px;
  }

  :deep(.tooltip) {
    margin-top: 20px;
  }
}
</style>
