<template>
  <div class="payments">
    <div v-if="plan !== 'black'" class="title">
      <AppText :class="{ red: count <= 0 }">
        {{ count > 0 ? t('merchantBilling.balance.paymentsLeft') : t('merchantBilling.balance.noPaymentsLeft') }}
      </AppText>
      <AppText
        :class="{ red: count <= 0 }"
        class="font-semibold"
        size="18px"
      >
        {{ count }}<span style="opacity: 0.4">/{{ max }}</span>
      </AppText>
    </div>

    <div
      v-if="plan !== 'black'"
      :class="{ warning: count <= 0 }"
      class="progress"
    >
      <div class="line">
        &nbsp;
      </div>
    </div>

    <div v-if="plan === 'black'" class="black">
      <AppIcon name="moebius" size="24px" />
      <AppText size="15px" weight="600">
        {{ t('merchantBilling.balance.unlimitedPayments') }}
      </AppText>
    </div>

    <AppText
      v-if="!isLifetime"
      variant="div"
      opacity="0.5"
      mt="8px"
    >
      {{ statusMessage }}
    </AppText>
  </div>
</template>

<script setup>
import { computed } from 'vue';

import { i18n } from '@/plugins/localization';

const props = defineProps({
  plan: {
    type: String,
    default: '-',
  },
  count: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 0,
  },
  daysLeft: {
    type: [Number, String],
    default: 0,
  },
  isCancelled: {
    type: Boolean,
    default: false,
  },
  isLifetime: {
    type: Boolean,
    default: false,
  },
});

const progress = computed(() => (`${((props.count / props.max) * 100)}%`));
const { t } = i18n.global;

const statusMessage = computed(() => {
  if (props.isCancelled) {
    if (props.daysLeft === 0) {
      return t('merchantBilling.balance.expiresToday');
    }
    if (props.daysLeft === 1) {
      return t('merchantBilling.balance.expiresTomorrow');
    }

    return t('merchantBilling.balance.expiresInDays', { value: props.daysLeft }, props.daysLeft);
  }

  if (props.plan === 'FREE') {
    if (props.daysLeft === 0) {
      return t('merchantBilling.balance.quotaResetsToday');
    }
    if (props.daysLeft === 1) {
      return t('merchantBilling.balance.quotaResetsTomorrow');
    }

    return t('merchantBilling.balance.quotaResetsInDays', { value: props.daysLeft }, props.daysLeft);
  }

  if (props.daysLeft === 0) {
    return t('merchantBilling.balance.renewalToday');
  }
  if (props.daysLeft === 1) {
    return t('merchantBilling.balance.renewalTomorrow');
  }

  return t('merchantBilling.balance.renewalInDays', { value: props.daysLeft }, props.daysLeft);
});
</script>

<style lang="scss" scoped>
.payments {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .black {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .progress {
    margin-top: 10px;
    width: 100%;
    height: 15px;
    background: var(--color-primary-01);
    border-radius: 20px;

    .line {
      max-width: 100%;
      width: v-bind('progress');
      height: 100%;
      min-width: 15px;
      background: var(--color-primary);
      border-radius: 20px;
      @include transition-base(width);
    }
  }

  .warning {
    background: var(--color-error-01);

    .line {
      background: var(--color-error-01);
    }
  }

  .red {
    color: var(--color-error);
  }
}
</style>
