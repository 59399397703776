<template>
  <div
    class="title"
    :class="{ close: isClosed }"
    @click="isClosed = !isClosed"
  >
    <AppText
      variant="div"
      size="15px"
      class="font-medium"
      :color="titleColor"
    >
      {{ statusMessage }}
    </AppText>
    <DropdownArrow :is-open="!isClosed" :class="{ grey: !isClosed }" />
  </div>
  <transition name="elui">
    <div v-if="!isClosed" class="info">
      <AppText variant="div" mb="16px">
        <template v-if="activeSubscriptions === 0">
          {{ t('merchantBilling.balance.anySubscriptionsWillBeCancelled') }}
        </template>
        <template v-else>
          {{ t('merchantBilling.balance.someSubscriptionsWillBeCancelled', { value: activeSubscriptions }) }}
        </template>
      </AppText>
      <slot />
    </div>
  </transition>
</template>

<script>
import { computed, ref } from 'vue';

import DropdownArrow from '@/components/Icon/DropdownArrow.vue';

import { i18n } from '@/plugins/localization';

export default {
  name: 'NotifyBlock',
  components: {
    DropdownArrow,
  },
  props: {
    activeSubscriptions: {
      type: Number,
      default: 0,
    },
    daysLeft: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const { t } = i18n.global;
    const isClosed = ref(true);
    const titleColor = computed(() => (isClosed.value ? 'white' : 'black'));

    const statusMessage = computed(() => {
      if (props.daysLeft === 0) {
        return t('merchantBilling.balance.downgradesToday');
      }
      if (props.daysLeft === 1) {
        return t('merchantBilling.balance.downgradesTomorrow');
      }

      return t('merchantBilling.balance.downgradesInValueDays', { value: props.daysLeft }, props.daysLeft);
    });

    return { isClosed, titleColor, statusMessage };
  },
};
</script>

<style lang="scss" scoped>
.title {
  background: #F0F0F0;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 40px;
  border-right: 1px solid var(--color-black-01);
  border-bottom: 1px solid var(--color-black-01);
  @include transition-base('color,background');

  &.close {
    background: var(--color-error);
    @include transition-base(background);
  }

  :deep(.icon) {
      opacity: 1;
      path {
        fill: white;
      }
  }

  .grey :deep(path) {
    fill: var(--color-black);
  }
}

.info {
  background: #F0F0F0;
  border-right: 1px solid var(--color-black-01);
  border-bottom: 1px solid var(--color-black-01);
  width: 100%;
  padding: 20px 40px 30px;
}
</style>
