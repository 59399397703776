<template>
  <FButton
    size="x-small"
    no-margin
    is-full-width
    @click="onStopCancellationPlan"
  >
    {{ t('merchantBilling.balance.label.stopCancellation') }}
  </FButton>
</template>

<script setup>
import { changeMerchantPlanStatus } from '@/api/merchant';
import { emitter } from '@/composables/useBus';
import { i18n } from '@/plugins/localization';

const { t } = i18n.global;

const onStopCancellationPlan = async () => {
  const { isSuccess, successHandler } = await changeMerchantPlanStatus({ isActive: true });

  if (isSuccess) {
    emitter.emit('update-billing-page');
    successHandler(t('merchantBilling.notification.planCancellationStopped'));
  }
};
</script>
