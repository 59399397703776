<template>
  <SmoothModal
    :model-value="isOpenProp"
    width="440px"
    :is-description-visible="false"
    :has-header="false"
    @update:model-value="isOpenProp = false"
  >
    <div style="padding-top: 30px;">
      <AppIcon name="warning-modal" size="40px" is-img-tag />
    </div>
    <AppText
      variant="div"
      size="17px"
      mt="20px"
      mb="8px"
      class="font-medium"
    >
      {{ t('merchantBilling.modalToCancelPlan.title') }}
    </AppText>

    <AppText variant="div" :line-height="1.5">
      {{ t('merchantBilling.modalToCancelPlan.description') }}
    </AppText>

    <AppText
      variant="div"
      :line-height="1.5"
      opacity="0.4"
      mt="20px"
      type="uppercase"
    >
      {{ t('merchantBilling.modalToCancelPlan.freeLimitations') }}
    </AppText>

    <div class="limitations">
      <div class="limitations__item">
        <AppIcon name="triangle-circle-red" size="20px" />
        <div class="info">
          <AppText
            variant="div"
            size="15px"
            class="font-medium"
          >
            {{ t('merchantBilling.modalToCancelPlan.paymentsPerMonth') }}
          </AppText>
          <AppText
            variant="div"
            :line-height="1.5"
            mt="4px"
          >
            {{ t('merchantBilling.modalToCancelPlan.cycleBegins') }}
          </AppText>
        </div>
      </div>

      <div class="limitations__item">
        <AppIcon name="close-circle-red" size="20px" />
        <div class="info">
          <AppText
            variant="div"
            size="15px"
            class="font-medium"
          >
            {{ t('merchantBilling.modalToCancelPlan.subscriptionsWillBeDisabled') }}
          </AppText>
          <AppText
            variant="div"
            :line-height="1.5"
            mt="4px"
          >
            {{ subscriptions }}
            {{ t('merchantBilling.modalToCancelPlan.subscriptionsWillBeCancelled') }}
          </AppText>
        </div>
      </div>
    </div>

    <div class="buttons">
      <FButton
        type="danger"
        no-margin
        @click="cancelPlan"
      >
        {{ t('common.confirm') }}
      </FButton>

      <FButton
        type="plain"
        no-margin
        @click="isOpenProp = false"
      >
        {{ t('common.notNow') }}
      </FButton>
    </div>
  </SmoothModal>
</template>

<script>
import { computed } from 'vue';

import SmoothModal from '@/components/SmoothModal';

import { changeMerchantPlanStatus } from '@/api/merchant';
import { useModel } from '@/composables/useModel';
import { i18n } from '@/plugins/localization';
import { emitter } from '@/composables/useBus';

export default {
  name: 'ModalToCancelPlan',
  components: {
    SmoothModal,
  },
  props: {
    activeSubscriptions: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const { value: isOpenProp } = useModel(props, emit, { prop: 'modelValue' });

    const subscriptions = computed(() => (props.activeSubscriptions > 0 ? props.activeSubscriptions : t('common.all')));

    const cancelPlan = async () => {
      const { isSuccess, successHandler } = await changeMerchantPlanStatus({ isActive: false });

      if (isSuccess) {
        emitter.emit('update-billing-page');
        emit('update:modelValue', false);
        successHandler(t('merchantBilling.notification.changeMerchantPlanStatusCancelled'));
      }
    };

    return {
      isOpenProp,
      cancelPlan,
      subscriptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
  display: flex;

  & > * {
    width: 50%;
  }
}

.limitations {
  margin-top: 20px;

  .limitations__item {
    display: flex;
    background-color: var(--color-error-01);
    padding: 16px;
    border-radius: 8px;

    &:not(:first-child) {
      margin-top: 8px;
    }

    :deep(.icon) {
      flex-shrink: 0;
    }

    .info {
      margin-left: 14px;
    }
  }
}
</style>
